<template>
  <v-card class="mx-auto" min-width="30%" max-width="400px" style="margin-top: 10%">
    <v-img src="../../../../assets/logo.png" width="100%"></v-img>

    <v-card-title> Reset Password </v-card-title>

    <v-card-subtitle>
      <p v-if="loaded && datediff > expireHrs">This link was expired</p>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col md="12" sm="12">
              <v-text-field
                v-model="resetForm.password"
                :rules="passwordRules"
                label="Password"
                required
                type="password"
                maxlength="18"
                id="password"
                ref="password"
              ></v-text-field>
            </v-col>
            <v-col md="12" sm="12">
              <v-text-field
                v-model="resetForm.confirmPassword"
                label="Confirm Password"
                :rules="confirmPasswordRules"
                required
                type="password"
                maxlength="18"
                id="confirmPassword"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <!-- approve dialog -->
        <v-btn
          color="secondary"
          @click="checkValid"
          :disabled="
            datediff > expireHrs ||
            resetForm.password == '' ||
            resetForm.confirmPassword == ''
          "
          >Confirm
        </v-btn>
        <v-dialog v-model="confirmDialog" persistent max-width="520">
          <!--template v-slot:activator="{ on }">
            <v-btn color="secondary" v-on="on">Confirm </v-btn>
          </!--template-->
          <v-card>
            <v-card-title class="headline">
              Confirm to reset your password ?
            </v-card-title>
            <!--v-card-text
                >Let Google help apps determine location. This means sending anonymous
                location data to Google, even when no apps are running.</!--v-card-text
              -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="confirmDialog = false">
                Cancel
              </v-btn>
              <v-btn color="green darken-1" text @click="resetPassword"> Confirm </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn color="error" class="mr-4" @click="reset" :disabled="datediff > expireHrs">
          Reset
        </v-btn>
      </v-form>
    </v-card-subtitle>
    <v-card-actions>
      <v-icon large color="blue">mdi-facebook</v-icon>
    </v-card-actions>
  </v-card>
</template>
<script>
import axios from "axios";
//import { generateJwtToken } from "../../../../plugins/middleware";
//import axios from 'axios';
export default {
  name: "forgetPassword",
  data() {
    return {
      valid: false,
      loaded: false,
      hasError: false,
      expireHrs: 24,
      confirmDialog: false,
      user: {},
      url: process.env.VUE_APP_LOYALTY_API_URI,
      resetInfo: "",
      resetForm: {
        password: "",
        confirmPassword: "",
      },
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /[^@]+@[^@]+.[a-zA-Z]{2,6}/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,15}$/.test(
            v
          ) ||
          "Password must have Numberic, Lowercase, Uppercase, special charactor ( !@#$%^&* ) and must between 6-12 charactors ",
      ],

      confirmPassword: "",
      confirmPasswordRules: [
        (v) => !!v || "Confirm password is required",
        (v) => {
          let pwd = document.getElementById("password");
          //console.log(pwd.value);
          if (pwd != "undefined") {
            if (pwd.value == v) {
              return true;
            } else {
              return "Confirm password must equal password ";
            }
          }
        },
      ],
    };
  },
  computed: {
    datediff() {
      console.log("computed");
      let cTime = new Date().getTime();
      return Math.abs((cTime - this.resetInfo.resetPass.resetTime) / (1000 * 60 * 60));
    },
  },
  mounted() {
    console.log("mounted");
    console.log("Reset Password" + this.$route.params.code);
    this.resetInformation();
  },
  methods: {
    async resetInformation() {
      //
      let payload = {
        code: this.$route.params.code,
      };
      //console.log(payload);
      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/getResetPasswordInfo",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            //console.log(response);
            if (response.data.statusCode == 0) {
              if (response.data.data.length > 0) {
                //
                this.resetInfo = response.data.data[0];
                //console.log(this.resetInfo);
              } else {
                alert("Code not found!");
              }
            }
          });
        this.loaded = true;
      } catch (e) {
        alert(e);
      }

      if (this.datediff > 0) {
        console.log(this.datediff);
      }
    },
    async resetPassword() {
      //console.log("login clicked");
      //this.$emit("forgetPassword", this.user);
      if (!this.hasError) {
        // call reset password
        let payload = {
          resetCode: this.$route.params.code,
          password: this.resetForm.password,
        };
        try {
          await axios
            .post(
              this.url + "/api/bfc/v1/doctor/resetPassword",
              payload, // need to change
              {
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.statusCode == 0) {
                if (response.data.data.nModified > 0) {
                  //
                  //console.log(this.resetInfo);
                  alert("Your password has been change");
                  this.$router.push("/doctor");
                } else {
                  alert("Reset failed");
                }
              }
            });
          this.loaded = true;
        } catch (e) {
          alert(e);
        }
      }
      this.confirmDialog = false;
    },
    reset() {
      this.resetForm = {
        password: "",
        confirmPassword: "",
      };
    },
    async checkValid() {
      //

      console.log("checkValid");

      let valid = this.$refs.form.validate();
      this.hasError = false;
      let breakForEachException = {};
      await Object.keys(this.resetForm).forEach((f) => {
        console.log(f);
        console.log(this.resetForm[f]);
        if (!this.resetForm[f]) {
          this.hasError = true;
          document.getElementById(f).focus();
          throw breakForEachException;
        }
      });
      console.log(this.hasError);
      console.log(valid);
      if (!this.hasError && valid) {
        this.confirmDialog = true;
      } else {
        this.confirmDialog = false;
      }

      //return this.hasError;
    },
  },
  watch: {
    confirmDialog: {
      handler(val) {
        console.log(val);
        if (val) {
          //this.checkValid();
          //this.confirmDialog = false;
        }
      },
    },
  },
};
</script>
